import { useState, useEffect, useContext } from "react";
import ExploreCard from "./ExploreCard";
import Web3Context from "../../contexts/Web3Context";
import { useParams } from "react-router-dom";
import ALL_PROJECT_DETAILS from "../../allProjects.json";
export default function Explore() {
  const [stakedcount, setStakedCount] = useState(false);
  const [unstakedcount, setUnstakedCount] = useState(false);
  const id = "crusaders";

  const {
    account,
    getUserStaked,
    getUserUnStaked,
    claimRewardsCrusader,
    claimReflections,
    unstakeEmergency: unstakeCrusader,
    stakeCrusader,
    contractObjects,
    calculateRewardCrusader,
    selectedProject,
    setSelectedProject,
    update: globalUpdate,
  } = useContext(Web3Context);
  const projectDetails = ALL_PROJECT_DETAILS[selectedProject];

  const [stakeddata, setstakeddata] = useState(false);
  const [update, setUpdate] = useState(0);
  const [unstaked, setunstaked] = useState(false);
  async function getdata() {
    const [t, k] = await Promise.all([getUserStaked(), getUserUnStaked()]);
    setstakeddata(t);
    setStakedCount(t?.length);
    setunstaked(k);
    setUnstakedCount(k?.length);
  }
  async function claimRewardsclicked(e) {
    e.preventDefault();
    await claimRewardsCrusader(stakeddata);
    setUpdate((val) => val + 1);
  }
  async function unstakeclicked(e) {
    e.preventDefault();
    await unstakeCrusader(stakeddata);
    setUpdate((val) => val + 1);
  }
  async function stakeclicked(e) {
    e.preventDefault();
    await stakeCrusader(unstaked);
    setUpdate((val) => val + 1);
  }
  async function claimReflectionsclicked(e) {
    e.preventDefault();
    const tokens = await calculateRewardCrusader(stakeddata);
    console.log("tokens", tokens);
    await claimReflections(tokens[1]);
    setUpdate((val) => val + 1);
  }
  useEffect(() => {
    if (id) {
      setSelectedProject("crusaders");
    }
    console.log(contractObjects, account);
    if (contractObjects && account) {
      getdata();
    }
  }, [update, account, id, contractObjects, globalUpdate]);
  console.log(selectedProject);
  const [staked, setStaked] = useState(true);
  return (
    <section className="explore-area pt-0">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12">
            <div className="explore-menu btn-group flex-wrap justify-content-center text-center mb-4">
              <button
                className="btn btn-bordered-white btn-smaller mt-3"
                onClick={(e) => {
                  e.preventDefault();
                  setStaked(true);
                }}
              >
                Staked
              </button>
              <button
                className="btn btn-bordered-white btn-smaller mt-3"
                onClick={(e) => {
                  e.preventDefault();
                  setStaked(false);
                }}
              >
                Unstaked
              </button>
            </div>
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-12">
            <div className="explore-menu btn-group flex-wrap justify-content-center text-center mb-4">
              {staked ? (
                stakedcount ? (
                  <>
                    You have {stakedcount} staked{" "}
                    {stakedcount == 1 ? "NFT" : "NFTS"}
                  </>
                ) : (
                  "You don't have any staked NFTS"
                )
              ) : unstakedcount ? (
                <>
                  You have {unstakedcount} unstaked{" "}
                  {unstakedcount == 1 ? "NFT" : "NFTS"}
                </>
              ) : (
                "You don't have any unstaked NFTS"
              )}
            </div>
          </div>
        </div>
        {staked ? (
          <div className="row justify-content-center text-center">
            <div className="col-12">
              {stakeddata && stakeddata.length != 0 && (
                <div className="explore-menu btn-group flex-wrap justify-content-center text-center mb-4">
                  <button
                    className="btn btn-bordered-white btn-smaller mt-3"
                    onClick={unstakeclicked}
                  >
                    Unstake all
                  </button>
                  <button
                    className="btn btn-bordered-white btn-smaller mt-3"
                    onClick={claimRewardsclicked}
                  >
                    Claim Reward All
                  </button>
                  {selectedProject?.showReflection && (
                    <button
                      className="btn btn-bordered-white btn-smaller mt-3"
                      onClick={claimReflectionsclicked}
                    >
                      Reflect All
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="row justify-content-center text-center">
            <div className="col-12">
              {unstaked && unstaked.length != 0 && (
                <div className="explore-menu btn-group flex-wrap justify-content-center text-center mb-4">
                  <button
                    className="btn btn-bordered-white btn-smaller mt-3"
                    onClick={stakeclicked}
                  >
                    Stake All
                  </button>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="row items justify-content-center explore-items">
          {staked &&
            stakeddata &&
            (stakeddata.length ? (
              stakeddata.map((item) => (
                <ExploreCard key={item} token={item} staked={staked} />
              ))
            ) : (
              <></>
            ))}
          {!staked &&
            unstaked &&
            (unstaked.length ? (
              unstaked.map((item) => (
                <ExploreCard key={item} staked={staked} token={item} />
              ))
            ) : (
              <></>
            ))}
        </div>
      </div>
    </section>
  );
}
