import {useContext,useEffect} from "react";
import { useMetaMask } from "metamask-react";
import Explore from "./Explore";
import ALL_PROJECT_DETAILS from "../../allProjects.json";
import Web3Context from '../../contexts/Web3Context'
import { useParams } from "react-router-dom";
export default function ProjectsDetails() {
  const { connectWallet, account,selectedProject,setSelectedProject } = useContext(Web3Context);
  const {id} = useParams();
  const projectDetails = ALL_PROJECT_DETAILS[id];

        return (
            <div className="main">
                <section className="text-center pb-0" >
                                <h2 className="mt-4">{projectDetails?.title}</h2>
                </section>
                {account?<Explore />:<div className="text-center"><button className="btn btn-bordered-white btn-smaller mt-3" onClick={()=>connectWallet()}>Connect Wallet</button></div>}
                {/* <ModalSearch /> */}
                {/* <ModalMenu /> */}
                {/* <Scrollup /> */}
            </div>
        );
    }

