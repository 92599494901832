import { createContext, useEffect, useState } from "react";
import { ethers } from "ethers";

import chibiCats from "../abis/chibiCats.json";
import chibiDust from "../abis/chibiDust.json";
import chibiStaking from "../abis/chibiStaking.json";
import crusaderStakingAbi from "../abis/crusaderAbi.json";
import raveTokensAbi from "../abis/raveTokens.json";
import {
  Provider as MulticallProvider,
  Contract as MulticallContract,
} from "ethers-multicall";
import { BigNumber, utils } from "../../node_modules/ethers/lib/ethers";
import { toast } from "react-toastify";
import axios from "axios";
import ALL_CONTRACTS from "../allProjects.json";

const Web3Context = createContext();

// const RPC_URL = "https://api.s0.b.hmny.io";
// const CHAIN_ID = 1666700000;
// const NATIVE_CURRENCY = {
//   name: "one",
//   symbol: "ONE", // 2-6 characters long
//   decimals: 18,
// };
// const MULTI_CALL_ADDRESS = "0xd078799c53396616844e2fa97f0dd2b4c145a685";
// const CHAIN_NAME = "Harmony Testnet";
// const BASE_URL = "http://localhost:5000/proposal";

const RPC_URL = "https://rpc.hermesdefi.io/";
const CHAIN_ID = 1666600000;
const NATIVE_CURRENCY = {
  name: "one",
  symbol: "ONE", // 2-6 characters long
  decimals: 18,
};
const MULTI_CALL_ADDRESS = "0x34b415f4d3b332515e66f70595ace1dcf36254c5";
const CHAIN_NAME = "Harmony Mainnet";
const BASE_URL = "http://localhost:5000/proposal";

export const Web3Provider = (props) => {
  const [account, setAccount] = useState();
  const [signer, setSigner] = useState();
  const [contractObjects, setContractObjects] = useState();
  const [contractAddresses, setContractAddresses] = useState({
    NFT_CONTRACT_ADDRESS: "",
    STAKING_CONTRACT_ADDRESS: "",
    COIN_CONTRACT_ADDRESS: "",
  });
  const [selectedProject, setSelectedProject] = useState();
  const [baseCoinPrice, setBaseCoinPrice] = useState();
  const [isPaused, setIsPaused] = useState();
  const [update, setUpdate] = useState(0);
  const [stats, setStats] = useState({});
  const functionsToExport = {};

  const onAccountsChanged = async (accounts) => {
    setAccount(accounts[0]);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const _signer = provider.getSigner();
    setSigner(_signer);
  };
  useEffect(() => {
    if (selectedProject && ALL_CONTRACTS[selectedProject]) {
      setContractAddresses(ALL_CONTRACTS[selectedProject]?.contractAddress);
      setupContracts(signer, ALL_CONTRACTS[selectedProject]?.contractAddress);
    }
  }, [selectedProject, signer]);

  const addNewChain = async () => {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: `0x${CHAIN_ID.toString(16)}`,
          rpcUrls: [RPC_URL],
          chainName: CHAIN_NAME,
          nativeCurrency: NATIVE_CURRENCY,
        },
      ],
    });
  };
  const setupContracts = async (signer, _contractAddresses) => {
    setContractAddresses(_contractAddresses);
    const _signer =
      signer || new ethers.providers.Web3Provider(window.ethereum, "any");
    const {
      NFT_CONTRACT_ADDRESS,
      STAKING_CONTRACT_ADDRESS,
      COIN_CONTRACT_ADDRESS,
    } = _contractAddresses;
    const chibiContract = new ethers.Contract(
      NFT_CONTRACT_ADDRESS,
      chibiCats,
      _signer
    );
    const stakingContract = new ethers.Contract(
      STAKING_CONTRACT_ADDRESS,
      chibiStaking,
      _signer
    );
    const dustContract = new ethers.Contract(
      COIN_CONTRACT_ADDRESS,
      chibiDust,
      _signer
    );
    const stakingContractCrusader = new ethers.Contract(
      STAKING_CONTRACT_ADDRESS,
      crusaderStakingAbi,
      _signer
    );
    const raveContract = new ethers.Contract(
      "0x7dcFD5C3e532446850185aca933430B3C3094958",
      raveTokensAbi,
      _signer
    );

    const _contractObjects = {
      chibiContract,
      stakingContract,
      dustContract,
      stakingContractCrusader,
      raveContract,
    };
    setContractObjects(_contractObjects);
  };
  const switchCain = async () => {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${CHAIN_ID.toString(16)}` }],
    });
  };
  const promptChain = async () => {
    try {
      await switchCain();
    } catch (e) {
      await addNewChain();
      // await switchCain();
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const _signer = provider.getSigner();
    setSigner(_signer);
  };
  const onChainChanged = async (chainID) => {
    await promptChain();
  };
  const setupMultiCallContract = async (contractAddress, abi) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const ethcallProvider = new MulticallProvider(provider);

    await ethcallProvider.init();
    ethcallProvider._multicallAddress = MULTI_CALL_ADDRESS;
    console.log(contractAddress, abi);
    console.log(provider);
    console.log(ethcallProvider);

    const multicallContract = new MulticallContract(contractAddress, abi);
    return [ethcallProvider, multicallContract];
  };
  functionsToExport.connectWallet = async (defaultAccount = -1) => {
    const { ethereum } = window;

    if (!ethereum) {
      toast.error("You need a wallet to continue!");
      return;
    }

    if (ethereum) {
      await ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await ethereum.request({ method: "eth_accounts" });
      await promptChain();
      ethereum.on("chainChanged", onChainChanged);
      ethereum.on("accountsChanged", onAccountsChanged);
      setAccount(accounts[0]);
      toast.success("Wallet Connected!");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const _signer = provider.getSigner();
      setSigner(_signer);
    }
  };

  functionsToExport.stake = async (tokenIds) => {
    try {
      const isPlotsApproved =
        await contractObjects?.chibiContract?.isApprovedForAll(
          account,
          contractAddresses?.STAKING_CONTRACT_ADDRESS
        );
      if (!isPlotsApproved) {
        toast(`Approving Staking Contract`);

        const approveIt =
          await contractObjects?.chibiContract?.setApprovalForAll(
            contractAddresses?.STAKING_CONTRACT_ADDRESS,
            true
          );
        const newBattleId = await approveIt.wait();
        toast(`Staking Contract Approved!`);
      }
      toast.success(`Placing Transaction`);

      const n = await contractObjects.stakingContract.stake(tokenIds);
      await n.wait();
      toast.success("Transaction Successful");
      setUpdate((u) => u + 1);

      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.unstakeEmergency = async (tokenIds) => {
    try {
      toast(`Placing Transaction`);

      const n = await contractObjects.stakingContract.emergencyUnstake(
        tokenIds
      );
      toast(`Transaction Placed`);

      await n.wait();
      toast.success("Transaction Successful!");
      setUpdate((u) => u + 1);

      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.unstake = async (tokenIds) => {
    try {
      toast(`Placing Transaction`);

      const n = await contractObjects.stakingContract.unStake(tokenIds);
      toast(`Transaction Placed`);

      await n.wait();
      toast.success("Transaction Successful!");
      setUpdate((u) => u + 1);

      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.claimRewards = async (tokenIds) => {
    try {
      toast(`Placing Transaction`);

      const n = await contractObjects.stakingContract.claimRewards(tokenIds);
      toast(`Transaction Placed`);

      await n.wait();
      toast.success("Transaction Successful!");
      setUpdate((u) => u + 1);
      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.claimReflections = async (tokenIds) => {
    try {
      toast(`Placing Transaction`);

      const n = await contractObjects.stakingContract.claimReflections(
        tokenIds
      );
      toast(`Transaction Placed`);

      await n.wait();
      toast.success("Transaction Successful!");
      setUpdate((u) => u + 1);

      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };

  functionsToExport.getUserStaked = async () => {
    // return [1, 2];
    try {
      const n = await contractObjects.stakingContract.getUserStaked(account);
      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.getContractBalance = async () => {
    const contractBalance = utils.formatEther(
      await contractObjects?.dustContract?.balanceOf(
        contractAddresses?.STAKING_CONTRACT_ADDRESS
      )
    );
    console.log(contractBalance);
    return contractBalance;
  };
  functionsToExport.getUserUnStaked = async () => {
    try {
      const userBalance = parseInt(
        (await contractObjects?.chibiContract?.balanceOf(account)).toString()
      );
      // const userBalance = 2;

      const [multicallProvider, multicallContract] =
        await setupMultiCallContract(
          contractAddresses?.NFT_CONTRACT_ADDRESS,
          chibiCats
        );
      let tokenCalls = [];
      for (let i = 0; i < userBalance; i++) {
        tokenCalls.push(multicallContract.tokenOfOwnerByIndex(account, i));
      }
      let userTokens = (await multicallProvider?.all(tokenCalls)).map((e) =>
        e.toString()
      );
      // const userTokens = ["30", "207"];
      console.log(selectedProject);
      if (selectedProject == "rave") {
        const [multicallProvider2, multicallContract2] =
          await setupMultiCallContract(
            "0x7dcFD5C3e532446850185aca933430B3C3094958",
            raveTokensAbi
          );
        let tokenCalls2 = [];

        for (let i = 0; i < userBalance; i++) {
          tokenCalls2.push(multicallContract2.canBeStaked(userTokens[i]));
        }
        console.log("Came here");
        let isStakeable = await multicallProvider2?.all(tokenCalls2);
        let filterTokens = [];
        console.log(isStakeable);
        isStakeable?.map((e, index) => {
          if (e) {
            filterTokens.push(userTokens[index]);
          }
        });

        return filterTokens;
      }

      return userTokens;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.getUserUnStakedCount = async () => {
    try {
      const n = parseInt(
        (await contractObjects?.chibiContract?.balanceOf(account)).toString()
      );
      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.getUserStakedCount = async () => {
    try {
      const n = await contractObjects.stakingContract.getUserStaked(account);
      return n.length;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.getRewards = async (tokenIds) => {
    try {
      const [multicallProvider, multicallContract] =
        await setupMultiCallContract(
          contractAddresses?.STAKING_CONTRACT_ADDRESS,
          chibiStaking
        );
      let tokenCalls = [];
      for (let i = 0; i < tokenIds.length; i++) {
        tokenCalls.push(multicallContract.getRewards(tokenIds[i]));
      }
      const rewards = (await multicallProvider?.all(tokenCalls)).map((e) =>
        e.toString()
      );
      return ethers.utils.formatEther(rewards[0]);
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };

  functionsToExport.calculateReward = async (tokenIds) => {
    try {
      const [multicallProvider, multicallContract] =
        await setupMultiCallContract(
          contractAddresses?.NFT_CONTRACT_ADDRESS,
          chibiCats
        );
      let tokenCalls = [];
      for (let i = 0; i < tokenIds.length; i++) {
        tokenCalls.push(multicallContract.calculateReward(tokenIds[i]));
      }
      const rewards = (await multicallProvider?.all(tokenCalls)).map((e) =>
        e.toString()
      );
      let rewardTokens = [];
      for (let i = 0; i < tokenIds.length; i++) {
        if (rewards[i]?.toString() !== "0") {
          rewardTokens.push(Number(tokenIds[i].toString()));
        }
      }
      return [rewards, rewardTokens];
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };

  functionsToExport.stakeCrusader = async (tokenIds) => {
    try {
      const isPlotsApproved =
        await contractObjects?.chibiContract?.isApprovedForAll(
          account,
          contractAddresses?.STAKING_CONTRACT_ADDRESS
        );
      if (!isPlotsApproved) {
        toast(`Approving Staking Contract`);

        const approveIt =
          await contractObjects?.chibiContract?.setApprovalForAll(
            contractAddresses?.STAKING_CONTRACT_ADDRESS,
            true
          );
        const newBattleId = await approveIt.wait();
        toast(`Staking Contract Approved!`);
      }

      toast.success(`Placing Transaction`);

      const n = await contractObjects.stakingContract.stake(tokenIds);
      await n.wait();
      setUpdate((u) => u + 1);
      toast.success("Transaction Successful");
      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.unstakeCrusader = async (tokenIds) => {
    try {
      const signatures = await axios.post(
        "https://crusader-staking-backend.onrender.com/all",
        {
          tokenIds: tokenIds.map((e) => parseInt(e.toString())),
        }
      );
      //   return;

      toast(`Placing Transaction`);
      console.log(signatures?.data?.data);
      const n = await contractObjects.stakingContractCrusader.unStake(
        signatures?.data?.data,
        { gasLimit: 10000000 }
      );
      toast(`Transaction Placed`);

      await n.wait();
      setUpdate((u) => u + 1);
      toast.success("Transaction Successful!");

      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.claimRewardsCrusader = async (tokenIds) => {
    try {
      const signatures = await axios.post(
        "https://crusader-staking-backend.onrender.com/all",
        {
          tokenIds: tokenIds.map((e) => parseInt(e.toString())),
        }
      );
      toast(`Placing Transaction`);

      const n = await contractObjects.stakingContractCrusader.claimRewards(
        signatures?.data?.data,
        { gasLimit: 10000000 }
      );
      setUpdate((u) => u + 1);
      toast(`Transaction Placed`);

      await n.wait();
      toast.success("Transaction Successful!");
      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.getUserStakedCrusader = async () => {
    try {
      const n = await contractObjects.stakingContract.getUserStaked(account);
      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.getUserUnStakedCrusader = async () => {
    try {
      const userBalance = parseInt(
        (await contractObjects?.chibiContract?.balanceOf(account)).toString()
      );
      // const userBalance = 2;
      chibiCats.map((e) => console.log(e));
      const [multicallProvider, multicallContract] =
        await setupMultiCallContract(
          contractAddresses?.NFT_CONTRACT_ADDRESS,
          chibiCats
        );
      let tokenCalls = [];
      for (let i = 0; i < userBalance; i++) {
        tokenCalls.push(multicallContract.tokenOfOwnerByIndex(account, i));
      }
      const userTokens = (await multicallProvider?.all(tokenCalls)).map((e) =>
        e.toString()
      );

      // const userTokens = ["30", "207"];
      return userTokens;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.getUserUnStakedCountCrusader = async () => {
    try {
      const n = parseInt(
        (await contractObjects?.chibiContract?.balanceOf(account)).toString()
      );
      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.getUserStakedCountCrusader = async () => {
    try {
      const n = await contractObjects.stakingContract.getUserStaked(account);
      return n.length;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.getRewardsCrusader = async (tokenIds) => {
    try {
      const [multicallProvider, multicallContract] =
        await setupMultiCallContract(
          contractAddresses?.STAKING_CONTRACT_ADDRESS,
          crusaderStakingAbi
        );
      let tokenCalls = [];
      const signatures = (
        await axios.post("https://crusader-staking-backend.onrender.com/all", {
          tokenIds: tokenIds.map((e) => parseInt(e.toString())),
        })
      )?.data?.data;
      for (let i = 0; i < tokenIds.length; i++) {
        tokenCalls.push(
          multicallContract.getRewards(
            signatures[i][0],
            signatures[i][1],
            signatures[i][2],
            signatures[i][3]
          )
        );
      }
      const rewards = (await multicallProvider?.all(tokenCalls)).map((e) =>
        e.toString()
      );
      return ethers.utils.formatEther(rewards[0]);
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };

  // functionsToExport.getUserUnStaked = async()=>{
  //   const data = await
  // };

  functionsToExport.calculateReward = async (tokenIds) => {
    try {
      const [multicallProvider, multicallContract] =
        await setupMultiCallContract(
          contractAddresses?.NFT_CONTRACT_ADDRESS,
          chibiCats
        );
      let tokenCalls = [];
      for (let i = 0; i < tokenIds.length; i++) {
        tokenCalls.push(multicallContract.calculateReward(tokenIds[i]));
      }
      const rewards = (await multicallProvider?.all(tokenCalls)).map((e) =>
        e.toString()
      );
      let rewardTokens = [];
      for (let i = 0; i < tokenIds.length; i++) {
        if (rewards[i]?.toString() !== "0") {
          rewardTokens.push(Number(tokenIds[i].toString()));
        }
      }
      return [rewards, rewardTokens];
    } catch (e) {
      console.log(tokenIds?.map((e) => e.toString()));
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };

  return (
    <Web3Context.Provider
      value={{
        account,
        isPaused,
        baseCoinPrice,
        selectedProject,
        setSelectedProject,
        contractObjects,
        stats,
        update,
        ...functionsToExport,
      }}
    >
      {props.children}
    </Web3Context.Provider>
  );
};
export default Web3Context;
