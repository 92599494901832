import React from "react";
import { useParams } from "react-router-dom";

export default function Pricing() {
  const { network } = useParams();
  return (
    <>
      <div className="maincontainer">
        <section>
          <div class="container py-5">
            <header class="text-center mb-5">
              <div class="row">
                <div class="col-lg-8 mx-auto">
                  <h1 style={{ color: "#ff66c4" }}>Pricing</h1>
                  <p style={{ fontSize: "1.4rem" }}> {network}</p>
                </div>
              </div>
            </header>
            {network === "Harmony" && (
              <div class="row text-center align-items-start justify-content-center">
                <div class="col-lg-4 mb-5 mb-lg-5 mx-4">
                  <div
                    class="p-5 rounded-lg shadow"
                    style={{ backgroundColor: "#16151a" }}
                  >
                    <h1
                      class="h5 text-uppercase font-weight-bold mb-4"
                      style={{ color: "#ff66c4" }}
                    >
                      Standard
                    </h1>
                    <div class="custom-separator my-4 mx-auto bg-primary"></div>
                    <ul class="list-unstyled my-5 text-small text-left text-white">
                      <li class="mb-3">
                        <span class="h5 font-weight-bold">150$ </span>first time
                        for 3 months
                      </li>
                      <div class="custom-separator my-4 mx-auto bg-primary"></div>
                      <li class="mb-3">
                        <span class="h5 font-weight-bold">100$ </span>
                        consecutive renewals for 3 months
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 mb-5 mb-lg-5 mx-4">
                  <div
                    class="p-5 rounded-lg shadow"
                    style={{ backgroundColor: "#16151a" }}
                  >
                    <h1
                      class="h5 text-uppercase font-weight-bold mb-4"
                      style={{ color: "#ff66c4" }}
                    >
                      Custom
                    </h1>

                    <div class="custom-separator my-4 mx-auto bg-primary"></div>
                    <ul class="list-unstyled my-5 text-small text-left text-white">
                      <li class="mb-3">
                        Based on requirements. Contact us for more details.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {network === "Polygon" && (
              <div class="row text-center align-items-start justify-content-center">
                <div class="col-lg-4 mb-5 mb-lg-5 mx-4">
                  <div
                    class="p-5 rounded-lg shadow"
                    style={{ backgroundColor: "#16151a" }}
                  >
                    <h1
                      class="h5 text-uppercase font-weight-bold mb-4"
                      style={{ color: "#ff66c4" }}
                    >
                      Standard
                    </h1>
                    <div class="custom-separator my-4 mx-auto bg-primary"></div>
                    <ul class="list-unstyled my-5 text-small text-left text-white">
                      <li class="mb-3">
                        <span class="h5 font-weight-bold">200$ </span>first time
                        for 3 months
                      </li>
                      <div class="custom-separator my-4 mx-auto bg-primary"></div>
                      <li class="mb-3">
                        <span class="h5 font-weight-bold">100$ </span>
                        consecutive renewals for 3 months
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 mb-5 mb-lg-5 mx-4">
                  <div
                    class="p-5 rounded-lg shadow"
                    style={{ backgroundColor: "#16151a" }}
                  >
                    <h1
                      class="h5 text-uppercase font-weight-bold mb-4"
                      style={{ color: "#ff66c4" }}
                    >
                      Custom
                    </h1>

                    <div class="custom-separator my-4 mx-auto bg-primary"></div>
                    <ul class="list-unstyled my-5 text-small text-left text-white">
                      <li class="mb-3">
                        Based on requirements. Contact us for more details.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {network === "Avax" && (
              <div class="row text-center align-items-start justify-content-center">
                <div class="col-lg-4 mb-5 mb-lg-5 mx-4">
                  <div
                    class="p-5 rounded-lg shadow"
                    style={{ backgroundColor: "#16151a" }}
                  >
                    <h1
                      class="h5 text-uppercase font-weight-bold mb-4"
                      style={{ color: "#ff66c4" }}
                    >
                      Standard
                    </h1>
                    <div class="custom-separator my-4 mx-auto bg-primary"></div>
                    <ul class="list-unstyled my-5 text-small text-left text-white">
                      <li class="mb-3">
                        <span class="h5 font-weight-bold">200$ </span>first time
                        for 3 months
                      </li>
                      <div class="custom-separator my-4 mx-auto bg-primary"></div>
                      <li class="mb-3">
                        <span class="h5 font-weight-bold">100$ </span>
                        consecutive renewals for 3 months
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 mb-5 mb-lg-5 mx-4">
                  <div
                    class="p-5 rounded-lg shadow"
                    style={{ backgroundColor: "#16151a" }}
                  >
                    <h1
                      class="h5 text-uppercase font-weight-bold mb-4"
                      style={{ color: "#ff66c4" }}
                    >
                      Custom
                    </h1>

                    <div class="custom-separator my-4 mx-auto bg-primary"></div>
                    <ul class="list-unstyled my-5 text-small text-left text-white">
                      <li class="mb-3">
                        Based on requirements. Contact us for more details.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {network === "Ethereum" && (
              <div class="row text-center align-items-start justify-content-center">
                <div class="col-lg-4 mb-5 mb-lg-5 mx-4">
                  <div
                    class="p-5 rounded-lg shadow"
                    style={{ backgroundColor: "#16151a" }}
                  >
                    <h1
                      class="h5 text-uppercase font-weight-bold mb-4"
                      style={{ color: "#ff66c4" }}
                    >
                      Standard
                    </h1>
                    <div class="custom-separator my-4 mx-auto bg-primary"></div>
                    <ul class="list-unstyled my-5 text-small text-left text-white">
                      <li class="mb-3">
                        <span class="h5 font-weight-bold">700$ </span>first time
                        for 3 months
                      </li>
                      <div class="custom-separator my-4 mx-auto bg-primary"></div>
                      <li class="mb-3">
                        <span class="h5 font-weight-bold">100$ </span>
                        consecutive renewals for 3 months
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 mb-5 mb-lg-5 mx-4">
                  <div
                    class="p-5 rounded-lg shadow"
                    style={{ backgroundColor: "#16151a" }}
                  >
                    <h1
                      class="h5 text-uppercase font-weight-bold mb-4"
                      style={{ color: "#ff66c4" }}
                    >
                      Custom
                    </h1>

                    <div class="custom-separator my-4 mx-auto bg-primary"></div>
                    <ul class="list-unstyled my-5 text-small text-left text-white">
                      <li class="mb-3">
                        Based on requirements. Contact us for more details.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
}
