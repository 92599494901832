// import Navbar from "./components/Navbar";
// // import { BrowserRouter, Route, Switch } from "react-router-dom";
// import "./sass/index.scss";
// import Home from "./pages/Home";
// import ProjectDetails from "./pages/ProjectDetails";
// import Details from "./pages/Details";
// function App() {
//   return (<div>
//     {/* <BrowserRouter> */}
//     <div className="app-container">
//       {/* <Navbar /> */}
//       <div className="pages">
//         {/* <Switch> */}
//         <Home />
//         {/* <Route exact path="/" component={Home} />
//           <Route path="/projects" component={ProjectDetails} />
//           <Route path="/details" component={Details} /> */}
//         {/* </Switch> */}
//       </div>
//     </div>
//     {/* </BrowserRouter > */}
//   </div>
//   );
// }

// export default App;
import { Web3Provider } from "./contexts/Web3Context";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// importing MyRouts where we located all of our theme
import MyRouts from "./routers/routes";
import ProjectsDetails from "./pages/ProjectDetails/index";
import ProjectsDetailsCrusaders from "./pages/Crusaders/index";
import Projects from "./pages/Projects";

import Home from "./pages/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pricing from "./pages/Pricing";

function App() {
  return (
    <div>
      <Web3Provider>
        <Header />

        <div style={{ minHeight: "99vh" }}>
          <ToastContainer />

          <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/explore" component={Projects} />
              <Route
                exact
                path="/projects/crusaders"
                component={ProjectsDetailsCrusaders}
              />
              <Route exact path="/projects/:id" component={ProjectsDetails} />
              <Route exact path="/pricing/:network" component={Pricing} />
            </Switch>
          </Router>
        </div>
        <Footer />

        {/* <MyRouts /> */}
      </Web3Provider>
    </div>
  );
}

export default App;
