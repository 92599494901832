import React from "react";
import Typewriter from "typewriter-effect";
import Header from "../components/Header/Header";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "../sass/components/_home.scss";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";

export default function Home() {
  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          style={{ marginTop: "100px" }}
          className="container hero-container"
        >
          <div
            style={{ display: "flex" }}
            className="d-flex my-auto text-center flex-column justify-center align-items-center"
          >
            {/* <span>{this.state.data.pre_heading}</span> */}
            <h1 className="mt-4 pt-4 flex" style={{ display: "flex" }}>
              <div
                className="inline"
                style={{ color: "#ff66c4", marginRight: "16px" }}
              >
                EZ{" "}
              </div>
              Staking
            </h1>
            <p>{"NFT Staking made Easier!"}</p>
            {/* Buttons */}
            <div className="button-group">
              <a
                className="btn btn-bordered-white m-2"
                target={""}
                href="/explore"
              >
                Explore
              </a>
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <h2>How it works?</h2>
          <Tabs className="Tabs">
            <TabList>
              <Tab>Standard Staking</Tab>
              <Tab>Custom Staking</Tab>
            </TabList>
            <TabPanel>
              <CardGroup>
                <Card className="mx-2">
                  {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
                  <Card.Body className="p-0">
                    <Card.Title>
                      <img
                        className="mx-auto"
                        style={{
                          maxHeight: "200px",
                        }}
                        src={"/img/NFT-img.png"}
                        alt="NFT-img"
                      />
                    </Card.Title>
                    <Card.Text>
                      User can stake any number of NFTs to receive a fixed
                      amount of tokens per day per NFT
                    </Card.Text>
                  </Card.Body>
                  {/* <Card.Footer>
                    <small className="text-muted">
                      Last updated 3 mins ago
                    </small>
                  </Card.Footer> */}
                </Card>
                <Card className="mx-2">
                  {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
                  <Card.Body className="p-0">
                    <Card.Title>
                      <img
                        className="mx-auto"
                        style={{
                          maxHeight: "200px",
                        }}
                        src={"/img/Reward-img.png"}
                        alt="Reward-img"
                      />
                    </Card.Title>
                    <Card.Text>
                      Admins can pause/resume and stop the rewards at any time
                    </Card.Text>
                  </Card.Body>
                  {/* <Card.Footer>
                    <small className="text-muted">
                      Last updated 3 mins ago
                    </small>
                  </Card.Footer> */}
                </Card>
                <Card className="mx-2">
                  {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
                  <Card.Body className="p-0">
                    <Card.Title>
                      <img
                        className="mx-auto"
                        style={{
                          maxHeight: "200px",
                        }}
                        src={"/img/Rate-change-img.png"}
                        alt="Rate-change-img"
                      />
                    </Card.Title>
                    <Card.Text>
                      Admins can change rate of rewards but this would affect
                      any pending rewards as well
                    </Card.Text>
                  </Card.Body>
                </Card>
              </CardGroup>
            </TabPanel>
            <TabPanel>
              <CardGroup>
                <Card
                  style={{
                    maxWidth: "500px",
                    margin: "auto",
                  }}
                >
                  {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
                  <Card.Body className="p-0">
                    <Card.Title>
                      <img
                        className="mx-auto"
                        style={{
                          maxHeight: "200px",
                        }}
                        src={"/img/Custom-nft-img.png"}
                        alt="Custom-nft-img"
                      />
                    </Card.Title>
                    <Card.Text>
                      <p>
                        We provide custom staking as a service, let us know your
                        requirements and we will make it for you.
                      </p>
                      <p>
                        For more info:
                        <a href="mailto:teamvikingstech@gmail.com">
                          {" "}
                          contact us
                        </a>
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </CardGroup>
            </TabPanel>
          </Tabs>
        </div>
        <div className="w-full">
          <h1
            className="mt-4 pt-4 w-full text-center"
            style={{ textAlign: "center" }}
          >
            <div className="inline" style={{ color: "#fff" }}>
              EZ Tools
            </div>
            <div>X</div>
            <img
              className="mx-auto"
              style={{
                height: "250px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={"/img/chibi_cats.png"}
            />
          </h1>
          <div
            style={{
              color: "#fff",
              fontSize: "22px",
              textAlign: "center",
              maxWidth: "800px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <b style={{ fontSize: "24px" }}>
              Founding Partnership with Chibi Cats
            </b>
            <br />
            <br />
            EzTools has partnered with Chibi Cats to bring easy solution for NFT
            staking for the community. Under this initiative we will reward
            Chibi Cat holders as this project grows and becomes more adopted.
          </div>
        </div>
        {/* Shape */}
        {/* <div className="shape">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 465" version="1.1">
                        <defs>
                            <linearGradient x1="49.7965246%" y1="28.2355058%" x2="49.7778147%" y2="98.4657689%" id="linearGradient-1">
                            <stop stopColor="rgba(69,40,220, 0.15)" offset="0%" />
                            <stop stopColor="rgba(87,4,138, 0.15)" offset="100%" />
                            </linearGradient>
                        </defs>
                        <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points fill="url(#linearGradient-1)">
                            <animate id="graph-animation" xmlns="http://www.w3.org/2000/svg" dur="2s" repeatCount attributeName="points" values="0,464 0,464 111.6,464 282.5,464 457.4,464 613.4,464 762.3,464 912.3,464 1068.2,464 1191.2,464 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,323.3 282.5,373 457.4,423.8 613.4,464 762.3,464 912.3,464 1068.2,464 1191.2,464 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,336.6 457.4,363.5 613.4,414.4 762.3,464 912.3,464 1068.2,464 1191.2,464 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,323.3 613.4,340 762.3,425.6 912.3,464 1068.2,464 1191.2,464 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,290.4 762.3,368 912.3,446.4 1068.2,464 1191.2,464 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,329.6 912.3,420 1068.2,427.6 1191.2,464 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,272 912.3,402.4 1068.2,373 1191.2,412 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,272 912.3,376 1068.2,336.6 1191.2,334 1328.1,404 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,272 912.3,376 1068.2,282 1191.2,282 1328.1,314 1440.1,372.8 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,272 912.3,376 1068.2,282 1191.2,204 1328.1,254 1440.1,236 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,272 912.3,376 1068.2,282 1191.2,204 1328.1,164 1440.1,144.79999999999998 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,272 912.3,376 1068.2,282 1191.2,204 1328.1,164 1440.1,8 1440.1,464 0,464;" fill="freeze" />
                            </polygon>
                        </g>
                    </svg>
                </div> */}
      </section>
    </>
  );
}
