import { get } from "axios";
import { useEffect, useState, useContext } from "react";
import Web3Context from "../../contexts/Web3Context";
import { ethers } from "ethers";
import ALL_PROJECT_DETAILS from "../../allProjects.json";
export default function ExploreCard({ token, staked }) {
  const {
    claimRewardsCrusader,
    claimReflections,
    stake,
    unstakeCrusader,
    getRewardsCrusader,
    calculateRewardCrusader,
    selectedProject,
    update,
  } = useContext(Web3Context);
  const projectDetails = ALL_PROJECT_DETAILS[selectedProject];
  async function stakeclick(e) {
    e.preventDefault();
    await stake([token]);
  }
  async function unstakeclick(e) {
    e.preventDefault();
    await unstakeCrusader([token]);
  }
  async function claimReflectionsclick(e) {
    e.preventDefault();
    await claimReflections([token]);
  }
  async function claimRewardsclick(e) {
    e.preventDefault();
    await claimRewardsCrusader([token]);
  }

  const [name, setName] = useState(false);
  const [metadata, setMetadata] = useState();
  const [reward, setReward] = useState(false);
  async function getdetails() {
    const { data } = await get(`${projectDetails?.dataUri}${token}`);
    setName(data.name);
    setMetadata(data);
    if (staked) {
      const reward = await getRewardsCrusader([token]);
      setReward(reward);
    }
  }
  useEffect(() => {
    getdetails();
  }, [update]);
  return (
    <div className="col-12 col-sm-6 col-lg-4 item explore-item">
      <div className="card">
        <div className="image-over">
          <img className="card-img-top" src={`${metadata?.image}`} alt="" />
        </div>
        {/* Card Caption */}
        <div className="card-caption col-12 p-0">
          {/* Card Body */}
          <div className="card-body">
            <h5 className="mb-2">{name}</h5>
            {/* <div className="seller d-flex align-items-center my-3">
                        <span>Owned By</span>
                        <a href="/author">
                            <h6 className="ml-2 mb-0">{owner}</h6>
                        </a>
                    </div> */}
            {staked && (
              <>
                <div className="card-bottom d-flex justify-content-between">
                  <span>Staking reward</span>
                  <span>
                    {parseFloat(reward).toFixed(5)} {projectDetails?.tokenName}
                  </span>
                </div>
                {projectDetails?.showReflection && (
                  <div className="card-bottom d-flex justify-content-between">
                    <span>Reflection Reward</span>
                  </div>
                )}
              </>
            )}
            <div className="card-bottom d-flex flex-wrap justify-content-around">
              {staked && (
                <>
                  <button
                    className="btn btn-bordered-white btn-smaller mt-3"
                    onClick={claimRewardsclick}
                  >
                    Claim reward
                  </button>
                  {projectDetails?.showReflection && (
                    <button
                      className="btn btn-bordered-white btn-smaller mt-3 ml-2"
                      onClick={claimReflectionsclick}
                    >
                      Claim Reflection
                    </button>
                  )}
                </>
              )}
              {staked ? (
                <button
                  className="btn btn-bordered-white btn-smaller mt-3"
                  onClick={unstakeclick}
                >
                  Unstake
                </button>
              ) : (
                <button
                  className="btn btn-bordered-white btn-smaller mt-3"
                  onClick={stakeclick}
                >
                  Stake
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
